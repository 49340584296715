:root {
  font-size: 10px;
  font-family: "Raleway", sans-serif;
}
body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif; */
  font-family: "Raleway", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

input[type="text"] {
  font-size: 13px;
  font-weight: 600;
}
input[type="email"] {
  font-size: 13px;
  font-weight: 600;
}
input[type="checkbox"] {
  font-size: 13px;
  font-weight: 400;
}
input[type="radio"] {
  font-size: 13px;
  font-weight: 600;
}
label {
  font-size: 13px;
  font-weight: 600;
}
input[type="password"] {
  font-size: 13px;
  font-weight: 600;
}
input[type="date"] {
  font-size: 13px;
  font-weight: 600;
}

/* custom scrollbar */
::-webkit-scrollbar {
  width: 12px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: #c4cde0;
  border: 3.6px solid transparent;
  background-clip: content-box;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #b8c0d2;
}
